import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import decode from 'jwt-decode';

@Injectable()

export class RoleGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const allowedRoles = route.data.allowedRoles;
    const token = sessionStorage.getItem('jwt_token');
    if (token) {
      // decode the token to get its payload
      const tokenPayload = decode(token);
      if (
        !this.auth.isAuthenticated() ||
        !allowedRoles.includes(tokenPayload.identity.user_type)
      ) {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
