import { Component, OnInit } from '@angular/core';
import { GlobalVariable } from 'src/app/globals';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.css']
})
export class HeaderToolbarComponent implements OnInit {

  user_full_name = null;
  user_email_address = null;
  user_university = null;

  base_url = GlobalVariable.BASE_API_URL;

  constructor(private spinner: NgxSpinnerService) {}

  logout = () => {

    this.spinner.show();

    $.ajax({
      url: `${this.base_url}/user/logout`,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({}),
      headers: {
        Authorization: `Bearer ${sessionStorage.jwt_token}`
      },
      success: resultData => {
        sessionStorage.clear();
        window.location.href = '/login';
        this.spinner.hide();
      },
      error: function(XMLHttpRequest, textStatus, errorThrown, resultData) {
        // alert(
        //   'Error Logging Out! \n\n' +
        //     JSON.stringify(XMLHttpRequest.responseJSON)
        // );
        sessionStorage.clear();
        window.location.href = '/login';
        this.spinner.hide();
      }
    });
  }

  ngOnInit() {
    const user_first_name = document.getElementById('user-first-name');
    const user_icon_name = document.getElementById('user-icon-name');

    const user_data = JSON.parse(sessionStorage.getItem('user_data'));
    if (user_data) {

      const first_name = user_data.first_name;
      const last_name = user_data.last_name;

      this.user_email_address = user_data.email;
      this.user_university = user_data.campus_id;

      this.user_full_name =
        first_name.charAt(0).toUpperCase() +
        first_name.slice(1) +
        ' ' +
        last_name.charAt(0).toUpperCase() +
        last_name.slice(1);

      user_first_name.innerHTML = this.user_full_name;
      user_icon_name.setAttribute('name', this.user_full_name);

    }
  }
}
