import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { GlobalVariable } from 'src/app/globals';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-check-in-card',
  templateUrl: './check-in-card.component.html',
  styleUrls: ['./check-in-card.component.css']
})
export class CheckInCardComponent implements OnInit {
  errorHtml = null;
  errorContent = null;
  base_url = GlobalVariable.BASE_API_URL;

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit() {
    // if (!sessionStorage.getItem('logged_in')) {
    //   window.location.href = '/login';
    // }
    this.errorHtml = document.getElementById('error-message');
    this.errorContent = document.getElementById('error-content');
  }

  checkIn = () => {

    this.spinner.show();

    $.ajax({
      url: `${this.base_url}/check_in/desktop`,
      type: 'PUT',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({}),
      headers: {
        Authorization: `Bearer ${sessionStorage.jwt_token}`
      },
      success: resultData => {
        console.log(resultData);
        // alert('Submit SUCCESS! \n\n' + JSON.stringify(resultData));
        sessionStorage.check_ins = JSON.stringify(resultData.check_ins);
        window.location.href = '/active-session-card';
        this.spinner.hide();
      },
      error: (XMLHttpRequest, textStatus, errorThrown, resultData) => {
        ////////////////////////////////////////
        // this is the space where we need to change the public: ShowError value to true.
        /////////////////////////////////////
        console.log(XMLHttpRequest.responseJSON.message);
        this.errorHtml.style.display = 'block';
        this.errorContent.innerHTML = XMLHttpRequest.responseJSON.message;
        console.log('Check-in Error');
        this.spinner.hide();
      }
    });
  }
}
