import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import * as $ from "jquery";
import { GlobalVariable } from "src/app/globals";
import { date_2_string, date_2_timer_string } from "src/app/utils";
import { timer } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-active-session-card",
  templateUrl: "./active-session-card.component.html",
  styleUrls: ["./active-session-card.component.css"]
})
export class ActiveSessionCardComponent implements OnInit {
  check_in_amount_html = null;
  check_in_start_time_html = null;
  check_in_zone_html = null;
  check_in_timer_html = null;
  am_pm_html = null;
  pay_rate_html = null;
  university_html = null;
  start_time = null;
  update_interval = null;
  check_in_interval = null;
  check_total_seconds = 0;
  session_summary = {};
  check_in_ids = [];
  base_url = GlobalVariable.BASE_API_URL;

  constructor(public dialog: MatDialog, private spinner: NgxSpinnerService) {}

  OpenZoneErrorDialog = status_code => {
    const dialogRef = this.dialog.open(WrongZoneDialogComponent);

    dialogRef.afterClosed().subscribe(() => {
      // console.log('error dialog');
      if (status_code === 400) {
        clearInterval(this.update_interval);
        clearInterval(this.check_in_interval);
        this.session_summary["end_time_datetime"] = new Date();
        sessionStorage["session_summary"] = JSON.stringify(
          this.session_summary
        );
        window.location.href = "/session-summary-card";
      } else {
        window.location.href = "/login";
      }
    });
  };

  OpenEndSessionDialog = () => {
    const dialogRef = this.dialog.open(EndSessionDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === "Cancel") {
        // console.log('Cancel Click Success');
      }
      if (result === "End") {
        this.endCheckIn();
        // console.log('Cancel Click Success');
      }
    });
  };

  endCheckIn = () => {
    this.spinner.show();

    $.ajax({
      url: `${this.base_url}/check_in/complete`,
      type: "GET",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify({}),
      headers: {
        Authorization: `Bearer ${sessionStorage.jwt_token}`
      },
      success: resultData => {
        console.log(resultData);
        // alert(JSON.stringify(resultData));
        clearInterval(this.update_interval);
        clearInterval(this.check_in_interval);
        this.session_summary["end_time_datetime"] = new Date();
        sessionStorage["session_summary"] = JSON.stringify(
          this.session_summary
        );

        // console.log(sessionStorage);
        window.location.href = "/session-summary-card";
        this.spinner.hide();
      },
      error: function(XMLHttpRequest, textStatus, errorThrown, resultData) {
        alert(
          "Could not end session! \n\n" +
            JSON.stringify(XMLHttpRequest.responseJSON)
        );

        // console.log('Check-in Error');
        this.spinner.hide();
      }
    });
  };

  checkIn = () => {
    $.ajax({
      url: `${this.base_url}/check_in/desktop`,
      type: "PUT",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify({}),
      headers: {
        Authorization: `Bearer ${sessionStorage.jwt_token}`
      },
      success: resultData => {
        // console.log(resultData);
        sessionStorage.check_ins = JSON.stringify(resultData.check_ins);
        this.update();
      },
      error: (XMLHttpRequest, textStatus, errorThrown, resultData) => {
        clearInterval(this.update_interval);
        clearInterval(this.check_in_interval);
        this.OpenZoneErrorDialog(XMLHttpRequest.status);
        // console.log('Check-in Error');
      }
    });
  };

  update_timer = () => {
    const check_ins = JSON.parse(sessionStorage.getItem("check_ins"));
    const start_time = new Date(check_ins[0].start_time);
    const timer_string = date_2_timer_string(start_time);
    this.check_in_timer_html.innerHTML = timer_string;
    this.session_summary["session_duration"] = timer_string;
  };

  update = () => {
    const check_ins = JSON.parse(sessionStorage.getItem("check_ins"));
    const start_time = new Date(check_ins[0].start_time);
    const start_time_hours = start_time.getHours();
    let amount_sum = 0.0;
    let pay_rate = 0;
    const start_time_list = date_2_string(start_time);
    const am_pm = start_time_list[1];
    const start_time_string = start_time_list[0];
    for (const check_in of check_ins) {
      amount_sum = amount_sum + check_in.amount;
    }
    for (const check_in of check_ins) {
      pay_rate = pay_rate + check_in.fund.pay_rate;
    }
    this.check_in_amount_html.innerHTML = amount_sum.toFixed(2);
    this.am_pm_html.innerHTML = "PM";
    this.check_in_start_time_html.innerHTML = start_time_string;
    this.check_in_zone_html.innerHTML = check_ins[0].zone.title;
    this.university_html.innerHTML = check_ins[0].zone.campus.title;
    this.pay_rate_html.innerHTML = `${pay_rate.toFixed(2)}/hr`;
    this.session_summary["amount"] = amount_sum;
    this.session_summary["pay_rate"] = pay_rate;
    this.session_summary["start_time"] = start_time_string;
    this.session_summary["am_pm"] = am_pm;
  };

  ngOnInit() {
    if (!sessionStorage.getItem("logged_in")) {
      window.location.href = "/login";
    }
    this.am_pm_html = document.getElementById("am-pm");
    this.pay_rate_html = document.getElementById("pay-rate");
    this.check_in_zone_html = document.getElementById("check-in-zone");
    this.university_html = document.getElementById("student-university");
    this.check_in_amount_html = document.getElementById("check-in-amount");
    this.check_in_start_time_html = document.getElementById(
      "check-in-start-time"
    );
    this.check_in_timer_html = document.getElementById("check-in-timer");
    this.checkIn();
    this.update();
    this.check_in_interval = setInterval(this.checkIn, 10000);
    this.update_interval = setInterval(this.update_timer, 1000);
  }
}

@Component({
  selector: "app-end-session-dialog",
  templateUrl: "end-session-dialog.component.html",
  styleUrls: ["./end-session-dialog.component.css"]
})
export class EndSessionDialogComponent {
  constructor(public dialogRef: MatDialogRef<EndSessionDialogComponent>) {}

  onNoClick(): void {
    this.closeDialog("Cancel");
    // console.log('Off Dialog Box Click - Continue Running');
  }

  CancelClick(): void {
    this.closeDialog("Cancel");
    // console.log('Cancel Click - Continue Running');
  }

  EndSessionClick(): void {
    this.closeDialog("End");
    // console.log('End Session Click - Call EndCheckIn()');
  }

  closeDialog(button: "End" | "Cancel") {
    this.dialogRef.close(button);
  }
}

@Component({
  selector: "app-wrong-zone-dialog",
  templateUrl: "wrong-zone-dialog.component.html",
  styleUrls: ["./wrong-zone-dialog.component.css"]
})
export class WrongZoneDialogComponent {
  constructor() {}
}
