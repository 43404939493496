import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HeaderToolbarComponent } from './header-toolbar/header-toolbar.component';

import { CheckInCardComponent } from './check-in-card/check-in-card.component';
import { ActiveSessionCardComponent } from './active-session-card/active-session-card.component';
import { SessionSummaryCardComponent } from './session-summary-card/session-summary-card.component';
import { LoginComponent } from './login/login.component';

import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from './auth/role-guard.service';

const routes: Routes = [
  {
    path: '',
    component: CheckInCardComponent,
    canActivate: [RoleGuard, AuthGuard],
    data: {
      allowedRoles: ['student', 'super_admin', 'admin']
    }
  },
  {
    path: 'header-toolbar',
    component: HeaderToolbarComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'check-in-card',
    component: CheckInCardComponent,
    canActivate: [RoleGuard, AuthGuard],
    data: {
      allowedRoles: ['student', 'super_admin', 'admin']
    }
  },
  {
    path: 'active-session-card',
    component: ActiveSessionCardComponent,
    canActivate: [RoleGuard, AuthGuard],
    data: {
      allowedRoles: ['student', 'super_admin', 'admin']
    }
  },
  {
    path: 'session-summary-card',
    component: SessionSummaryCardComponent,
    canActivate: [RoleGuard, AuthGuard],
    data: {
      allowedRoles: ['student', 'super_admin', 'admin']
    }
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
