export function date_2_string(date_object) {
  var am_pm = "AM";
  var time = date_object;
  var time_hours = time.getHours();
  if (time_hours >= 12) {
    time_hours = time_hours - 12;
    am_pm = "PM";
  }
  var time_string = `${time_hours.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false
  })}:${time.getMinutes().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false
  })}`;
  return [time_string, am_pm];
}

export function date_2_timer_string(start_date_time, end_date_time = new Date()) {
  var start_time = start_date_time;
  var end_time = end_date_time;
  var total_secs = (end_time.getTime() - start_time.getTime()) / 1000;
  var total_minutes = Math.floor(total_secs / 60);
  var hours = Math.floor(total_minutes / 60);
  var minutes = Math.floor(total_minutes - hours * 60);
  var seconds = Math.floor(total_secs - minutes * 60 - hours * 60 * 60);
  var timer_string = `${hours.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false
  })}:${minutes.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false
  })}:${seconds.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false
  })}`;
  return timer_string;
}
