import { Component, OnInit } from "@angular/core";
import { date_2_string, date_2_timer_string } from "src/app/utils";
import { GlobalVariable } from "src/app/globals";
import * as $ from "jquery";

@Component({
  selector: "app-session-summary-card",
  templateUrl: "./session-summary-card.component.html",
  styleUrls: ["./session-summary-card.component.css"]
})
export class SessionSummaryCardComponent implements OnInit {
  end_time_string = "";
  end_am_pm = "AM";
  session_summary = null;
  base_url = GlobalVariable.BASE_API_URL;
  check_ins = [];

  constructor() { }

  endTime = () => {
    const end_time = new Date(this.session_summary.end_time_datetime);
    const end_time_list = date_2_string(end_time);
    this.end_time_string = end_time_list[0];
    this.end_am_pm = end_time_list[1];
  };

  fetchSummary = (check_in_id) => {
    $.ajax({
      url: `${this.base_url}/check_in?check_in_id=${check_in_id}`,
      type: "GET",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      async: false,
      headers: {
        Authorization: `Bearer ${sessionStorage.jwt_token}`
      },
      success: resultData => {
        console.log("Finished Requesting")
        console.log(resultData);
        this.check_ins.push(resultData)
      },
      error: (XMLHttpRequest, textStatus, errorThrown, resultData) => {
        console.log("error fetching check-in summary");
      }
    })
  };

  calcSummary = () => {
    let check_ins = JSON.parse(sessionStorage.getItem("check_ins"));
    for (let check_in of check_ins) {
      this.fetchSummary(check_in.id)
    }

    console.log(this.check_ins)
    console.log("Fetched")

    if (this.check_ins[0]) {
      console.log("running")
      let check_in = this.check_ins[0];
      let start_time = new Date(check_in.start_time);
      let end_time = new Date(check_in.end_time);
      this.session_summary["amount"] = check_in.amount.toFixed(2);
      this.session_summary["session_duration"] = date_2_timer_string(start_time, end_time);
      [this.session_summary["start_time"], this.session_summary["start_time_am_pm"]] = date_2_string(start_time);
      [this.session_summary["end_time"], this.session_summary["end_time_am_pm"]] = date_2_string(end_time);
    }
  };

  ngOnInit() {
    // if (!sessionStorage.getItem('logged_in')) {
    //   window.location.href = '/login';
    // https://angular.io/api/common/DatePipe#input-value
    // }

    this.session_summary = {}
    this.calcSummary()

    const session_duration_html = document.getElementById("session-duration");
    const session_amount_html = document.getElementById("session-amount");
    const start_am_pm_html = document.getElementById("start-am-pm");
    const end_am_pm_html = document.getElementById("end-am-pm");
    const session_start_html = document.getElementById("session-start-time");
    const session_end_html = document.getElementById("session-end-time");

    session_duration_html.innerHTML = this.session_summary.session_duration;
    session_amount_html.innerHTML = this.session_summary.amount;
    start_am_pm_html.innerHTML = this.session_summary.start_time_am_pm;
    end_am_pm_html.innerHTML = this.session_summary.end_time_am_pm;
    session_start_html.innerHTML = this.session_summary.start_time;
    session_end_html.innerHTML = this.session_summary.end_time;
  }
}
