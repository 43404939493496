import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { GlobalVariable } from 'src/app/globals';
import { NgxSpinnerService } from 'ngx-spinner';

////////// Throw Error when invalid control is dirty, touched, or submitted ////////////
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted = false;

  errorHtml = null;
  errorContent = null;
  base_url = GlobalVariable.BASE_API_URL;

  loginForm: FormGroup = this.fb.group({
    Username: ['', [Validators.required, Validators.email]],
    Password: ['', Validators.required]
  });

  constructor(private fb: FormBuilder, private router: Router, private spinner: NgxSpinnerService) {}

  ngOnInit() {
    const header_toolbar = document.getElementById('header-toolbar');
    header_toolbar.style.display = 'none';
    sessionStorage.clear();

    this.errorHtml = document.getElementById('login-submit-error-message');
    this.errorContent = document.getElementById('login-submit-error-content');
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.spinner.show();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      // console.log(this.loginForm.value);
      this.spinner.hide();
      return;
    }

    // console.log(this.loginForm.value);
    const username = this.loginForm.value.Username;
    const password = this.loginForm.value.Password;

    if (username && password) {
      $.ajax({
        url: `${this.base_url}/user/login`,
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify({
          email: username,
          password: password
        }),
        success: resultData => {
          // console.log(resultData);
          // alert('Submit SUCCESS! \n\n' + JSON.stringify(resultData));
          sessionStorage['jwt_token'] = resultData.access_token;
          sessionStorage['refresh_token'] = resultData.refresh_token;
          sessionStorage['user_data'] = JSON.stringify(resultData.user);
          sessionStorage['logged_in'] = true;
          window.location.href = '/check-in-card';
          this.spinner.hide();
        },
        error: (XMLHttpRequest, textStatus, errorThrown, resultData) => {
          // alert(
          //   'Submit Error! \n\n' + JSON.stringify(XMLHttpRequest.responseJSON)
          // );
          console.log(XMLHttpRequest.responseJSON.message);
          this.errorHtml.style.display = 'block';
          this.errorContent.innerHTML = XMLHttpRequest.responseJSON.message;
          this.spinner.hide();
        }
      });
    } else {
      console.log('Sign In Error');
      this.spinner.hide();
    }
  }
}
